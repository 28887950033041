import React, { useState, useEffect } from "react";
import VideoFirst from "../Video/VideoFirst";
import CourseFirst from "../Course/CourseFirst";
import LiveCourseForm from "../Course/LiveCourse";
import CreateOfflineCourse from "../Course/CreateOfflineCourse";
import { useNavigate } from "react-router-dom";

export default function Studio() {
    const [activeComponent, setActiveComponent] = useState(null);
    const location = useNavigate()

    const handleUpload = (componentType) => {
        setActiveComponent(componentType);
    };

    useEffect(() => {
        sessionStorage.removeItem('UploadedCourseId');
    }, [location]);

    return (
        <>
            {activeComponent === "liveCourse" && <LiveCourseForm setStudioLandingPage={setActiveComponent} />}
            {activeComponent === "course" && <CreateOfflineCourse setStudioLandingPage={setActiveComponent}/>}
            {activeComponent === "uploadVideo" && <VideoFirst setStudioLandingPage={setActiveComponent}/>}
            
            {!activeComponent && (
                <div className="lms-flex mt-50 gap-2">
                    <div className="video-upload-card">
                        <img src="assets/create-course.jpg" />
                        <div className="lms-flex gap-2 py-3 align-items-center">
                            <img src="assets/info-btn.jpg" className="info-img" alt="" />
                            <p>Create a course with live, interactive sessions.</p>
                        </div>
                        <button 
                            className="primary-button sec-btn upload-btn" 
                            onClick={() => handleUpload("liveCourse")}
                        >
                            Create Live Course
                        </button>
                    </div>
                    <div className="video-upload-card">
                        <img src="assets/create-course-new.jpg" />
                        <div className="lms-flex gap-2 py-3 align-items-center">
                            <img src="assets/info-btn.jpg" className="info-img" alt="" />
                            <p>Create a course with videos and quizzes.</p>
                        </div>
                        <button 
                            className="primary-button sec-btn upload-btn" 
                            onClick={() => handleUpload("course")}
                        >
                            Create Course
                        </button>
                    </div>
                    <div className="video-upload-card">
                        <img src="assets/upload-video.jpg" />
                        <div className="lms-flex gap-2 py-3 align-items-center">
                            <img src="assets/info-btn.jpg" className="info-img" alt="" />
                            <p>Add videos later to course or finish learning in one.</p>
                        </div>
                        <button 
                            className="primary-button sec-btn upload-btn" 
                            onClick={() => handleUpload("uploadVideo")}
                        >
                            Upload Videos
                        </button>
                    </div>
                </div>
            )}
        </>
    );
}
