import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { FormControl, FormHelperText, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material';
import Spinner from '../../../components/Common/Spinner';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchAdminCourse } from '../store/actions/admin';
import { CourseType, validationLimits } from './Constants';
import API from '../utils/API';

const CourseCreation = forwardRef(({ setSelectedCategoryId, playlistCategories, fetchAdminCourseAction, setAlert, activeStep, setActiveStep, onCourseIdReceived, setCourseType, courses, formFilled, setFormFilled}, ref) => {
  const [courseName, setCourseName] = useState('');
  const [thumbnail, setThumbnail] = useState([]);
  const [modalLoader, setModalLoader] = useState(false);
  const [courseDescription, setCourseDescription] = useState('');
  const [selectedCategory, setSelectedCategory] = useState({ Id: null, Category: '' });
  const [thumbnailPreview, setThumbnailPreview] = useState('');
  const [courseNameError, setCourseNameError] = useState(false);
  const [courseDescriptionError, setCourseDescriptionError] = useState(false);
  const [courseCategoryError, setCourseCategoryError] = useState(false);
  const [thumbnailError, setThumbnailError] = useState(false);
  const [courseId, setCourseId] = useState(() => sessionStorage.getItem("UploadedCourseId") || null);
  const thumbnailFile = React.useRef(null);
  const [isCompChanged,setChangeOnComp] = useState(false);

  const isValidCourseName = courseName.trim().length > 0 && courseName.trim().length <= validationLimits.courseNameMax;
  const isValidCourseDescription = courseDescription.trim().length > 0 && courseDescription.trim().length <= validationLimits.courseDescriptionMax;
  const isValidCategory = selectedCategory.Id !== null;
  const isValidThumbnail = !thumbnailError && thumbnail.length > 0;


  const handleCourseName = (e) => {setCourseName(e.target.value), setChangeOnComp(true)};
  const handleCourseDescription = (e) => {setCourseDescription(e.target.value),setChangeOnComp(true)};
  const handleCategorySelectChange = (event) => {
    setChangeOnComp(true)
    const selectedCategory = playlistCategories.find(
      (category) => category.Id === event.target.value
    );
    setSelectedCategory(selectedCategory);
  };

  const handleThumbnail = (files) => {
    if (files && files[0]) {
      const file = files[0];
      const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
      const isValidFile = allowedTypes.includes(file.type) && /\.(png|jpg|jpeg)$/.test(file.name.toLowerCase());  
      const reader = new FileReader();
      reader.onload = (e) => setThumbnailPreview(e.target.result);
      reader.readAsDataURL(file);
      setThumbnail(files);
      setThumbnailError(!isValidFile);
    }
  };

  useEffect(() => {
    if (courseId) {
      const course = courses.find(course => course.Id === parseInt(courseId));
      if (course) {
        setCourseName(course.Name || '');
        setCourseDescription(course.Description || '');
        setThumbnailPreview(course.ThumbnailUrl || '')
  
        const category = playlistCategories.find(cat => cat.PlaylistCategoryName === course.CategoryName);
        if (category) {
          setSelectedCategory({ Id: category.Id, Category: category.PlaylistCategoryName });
        } else {
          setSelectedCategory({ Id: null, Category: '' });
        }
      }
    } else {
      setCourseName('');
      setCourseDescription('');
      setSelectedCategory({ Id: null, Category: '' });
    }
  }, [courseId, courses, playlistCategories]);
  
  useEffect(() => {
    const formValid =
      courseName.trim().length > 0 && courseName.trim().length <= validationLimits.courseNameMax &&
      courseDescription.trim().length > 0 && courseDescription.trim().length <= validationLimits.sessionDescriptionMax &&
      selectedCategory.Id !== null && !thumbnailError && thumbnailPreview.length > 0;

      formFilled(formValid);
      if (setFormFilled) {
        setFormFilled(formValid);
  }
}, [courseName, courseDescription, selectedCategory, thumbnailPreview]);


  const handleThumbnailClick = () => {
    thumbnailFile.current.click();
    setChangeOnComp(true)
  };

  useEffect(() => {
    if (isValidCourseName) {
      setCourseNameError(false);
    }
    if (isValidCourseDescription) {
      setCourseDescriptionError(false);
    }
    if (isValidCategory) {
      setCourseCategoryError(false);
    }
    if (isValidThumbnail) {
      setThumbnailError(false);
    }
}, [isValidCourseName, isValidCourseDescription, isValidCategory, isValidThumbnail]);


  useEffect(() => {
    fetchAdminCourseAction(1)
  }, [fetchAdminCourseAction])

  const handleCreateLiveCourse = () => {
    if (courseId !== null && isCompChanged) {
        if (courseName.trim().length > 0 && courseName.trim().length <= validationLimits.courseNameMax &&
            courseDescription.trim().length <= validationLimits.sessionDescriptionMax &&
            selectedCategory.Id !== null) {
            setModalLoader(true);
            const formData = new FormData();
            formData.append('Id', courseId);
            formData.append('Title', courseName.trim());
            formData.append('Description', courseDescription.trim());
            formData.append('CategoryId', selectedCategory.Id);
            formData.append('Tags', '');
            thumbnail.length > 0 && formData.append('filecontent', thumbnail[0]);
            formData.append('CourseType', CourseType.Online);

            API.updateCourseData(formData)
                .then(res => {
                    if (res.status === 201) {
                        setAlert({ active: true, severity: 'success', message: 'Course updated successfully' });
                    } else {
                        setAlert({ active: true, severity: 'error', message: 'Failed to update course' });
                    }
                })
                .catch(() => {
                    setAlert({ active: true, severity: 'error', message: 'Failed to update course' });
                })
                .finally(() => {
                    setModalLoader(false);
                    setActiveStep(activeStep + 1);
                });
        } else {
            setCourseNameError(!isValidCourseName);
            setCourseDescriptionError(!isValidCourseDescription);
            setCourseCategoryError(!isValidCategory);
            setThumbnailError(!isValidThumbnail);
            setFormFilled(false)
        }
    }
    else if (courseId === null) {
        if (isValidCourseName && isValidCourseDescription && isValidCategory && isValidThumbnail) {
            setModalLoader(true);
            const formData = new FormData();
            formData.append('Title', courseName.trim());
            formData.append('Description', courseDescription.trim());
            formData.append('PlaylistCategoryId', selectedCategory.Id);
            formData.append('Tags', '');
            formData.append('filecontent', thumbnail[0]);
            formData.append('CourseType', setCourseType);

            API.createLiveCourse(formData)
                .then(res => {
                    if (res.status === 200) {
                        const createdCourseId = res.data.CourseId;
                        setCourseId(createdCourseId);
                        sessionStorage.setItem("UploadedCourseId", createdCourseId);
                        onCourseIdReceived(createdCourseId);
                    } else {
                        setAlert({
                            active: true,
                            severity: 'error',
                            message: 'Failed to create live course'
                        });
                    }
                })
                .catch(() => {
                    setAlert({
                        active: true,
                        severity: 'error',
                        message: 'Error creating live course'
                    });
                })
                .finally(() => {
                    setModalLoader(false);
                    setActiveStep(activeStep + 1);
                });
        } else {
            setCourseNameError(!isValidCourseName);
            setCourseDescriptionError(!isValidCourseDescription);
            setCourseCategoryError(!isValidCategory);
            setThumbnailError(!isValidThumbnail);
        }
    } else {
        setCourseNameError(!isValidCourseName);
        setCourseDescriptionError(!isValidCourseDescription);
        setCourseCategoryError(!isValidCategory);
        setThumbnailError(!isValidThumbnail);
        setActiveStep(activeStep + 1);
    }
};


  useImperativeHandle(ref, () => ({
    triggerCreateLiveCourse: handleCreateLiveCourse,
  }));

  useEffect(()=>{
    setSelectedCategoryId(selectedCategory)
  })

  return (
    <div className='studio-course-creation'>
      {!modalLoader ? (
        <div className="form-body">
          <FormControl sx={{ width: '100%' }} className="form-control m-0">
            <h4>Course Title</h4>
            <input
              type='text'
              placeholder='Title of your Course goes here'
              value={courseName}
              onChange={handleCourseName}
              maxLength={validationLimits.courseNameMax}
              error={courseNameError}
            />
            {courseNameError && (
              <FormHelperText sx={{ fontSize: 12, color: 'red', margin: 0 }}>
                Course Name should be between 1-50 characters
              </FormHelperText>
            )}
          </FormControl>

          <input
            type="file"
            style={{ display: 'none' }}
            accept="image/png, image/jpeg, image/jpg"
            ref={thumbnailFile}
            onChange={(e) => handleThumbnail(e.target.files)}
          />

          {thumbnailPreview.length === 0 ? (
            <FormControl sx={{ width: '100%' }} className="form-control mb-4">
              <div className="webinar-upload-part-parent">
                <div className="video-upload thumb-upload webinar-upload-part" onClick={handleThumbnailClick}>
                  <svg width="36" height="36" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="64" height="64" rx="17.6" className="svg-for-menu"></rect>
                    <rect x="18.3994" y="15.2001" width="25.6" height="33.6" fill="white"></rect>
                    <path fillRule="evenodd" clipRule="evenodd" d="M16.1544 12.8C26.7177 12.8 37.2819 12.8 47.8453 12.8C49.6908 12.8 51.1998 14.3091 51.1998 16.1546C51.1998 26.718 51.1998 37.2822 51.1998 47.8455C51.1998 49.691 49.6908 51.2001 47.8453 51.2001C37.2819 51.2001 26.7177 51.2001 16.1544 51.2001C14.3089 51.2001 12.7998 49.691 12.7998 47.8455C12.7998 37.2821 12.7998 26.7179 12.7998 16.1546C12.7998 14.3091 14.3089 12.8 16.1544 12.8ZM41.0751 26.6586C40.1466 27.5764 39.2181 28.495 38.2896 29.4128C36.8498 27.9896 35.4092 26.5663 33.9694 25.143C33.9694 32.1992 33.9694 39.2563 33.9694 46.3126C32.6558 46.3126 31.343 46.3126 30.0294 46.3126C30.0294 39.2555 30.0294 32.1992 30.0294 25.1421C28.5896 26.5654 27.149 27.9896 25.7092 29.4128C24.7807 28.495 23.8521 27.5764 22.9236 26.6586C25.9491 23.6677 28.9738 20.6777 31.9993 17.6868C35.0249 20.6777 38.0496 23.6678 41.0751 26.6586H41.0751Z" className="svg-for-menu"></path>
                  </svg>
                  <h4>Upload thumbnail image (Only PNG/JPEG)</h4>
                </div>
                {thumbnailError && (
                  <FormHelperText sx={{ fontSize: 12, color: 'red' }}>
                    Upload only PNG/JPEG format
                  </FormHelperText>
                )}
              </div>
            </FormControl>
          ) : (
            <div className="replace-image-con">
              <img src={thumbnailPreview} className="thumbnail" alt="Thumbnail preview" />
              <button onClick={handleThumbnailClick}>Replace image</button>
              {thumbnailError && (
                <FormHelperText sx={{ fontSize: 10, color: 'red', position: 'absolute', top: '107px' }}>
                  Upload only PNG/JPEG format
                </FormHelperText>
              )}
            </div>
          )}

          <FormControl sx={{ width: '30%' }} className="admin-course-select-option mb-5">
            <InputLabel id="select-category-label" className="select-catagery">Select Category</InputLabel>
            <Select
              labelId="select-category-label"
              id="select-category"
              value={selectedCategory.Id || ''}
              onChange={handleCategorySelectChange}
              input={<OutlinedInput label="Select Category" />}
              error={courseCategoryError}
            >
              <MenuItem value="" disabled>Select Category</MenuItem>
              {playlistCategories.map((category) => (
                <MenuItem key={category.Id} value={category.Id}>
                  {category.PlaylistCategoryName}
                </MenuItem>
              ))}
            </Select>
            {courseCategoryError && (
              <FormHelperText sx={{ fontSize: 12, color: 'red' }}>
                Please select a Course Category
              </FormHelperText>
            )}
          </FormControl>

          <FormControl sx={{ width: '100%' }} className="form-control mb-4">
            <h4>About This Course</h4>
            <textarea
              id="course-description"
              value={courseDescription}
              rows="12"
              cols="50"
              maxLength="1000"
              placeholder="Add Course Description (Max 1000 characters)"
              onChange={handleCourseDescription}
            />
            {courseDescriptionError && (
              <FormHelperText sx={{ fontSize: 12, color: 'red' }}>
                Description cannot exceed 1000 characters
              </FormHelperText>
            )}
          </FormControl>
        </div>
      ) : (
        <div className="spinner-wrap">
          <Spinner animation="border" variant="dark" />
        </div>
      )}
    </div>
  );
});

const mapStateToProps = (state) => ({
  playlistCategories: state.category.playlistCategories,
  courses: state.courses.CollectionData.Playlist,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchAdminCourseAction: fetchAdminCourse,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(CourseCreation);
