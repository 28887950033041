import React, { Component } from "react";
import { postTutorialDocument } from "../store/actions/admin";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
import DocumentCheck from "../Common/DocumentCheck";
import * as constants from "../Common/Constants";
import API from '../utils/API';


class Draganddrop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      documents: [],
      updatedDocumentData:[],
      documentMaxLengthError:false,
      documentResponce:[],
      file:[]
    };
  }

  onDrop = async (files) => {
    this.setState({file : files})
    if (!constants.acceptedMime.includes(files[0].type)) {
      this.props.error('Document format incorrect');
      return;
    }
    else{
      this.props.error('');
    }
  
    const { VideoId, FileId } = this.props;
  
    if (this.state.documents.length + files.length > 5) {
      this.setState({ documentMaxLengthError: true });
      this.props.setDocumentMaxLengthError(true);
      return;
    }
  
    this.setState({ documentMaxLengthError: false });
    this.props.setDocumentMaxLengthError(false);
  
    const newFiles = files.filter(
      file => !this.state.documents.some(doc => doc.FileName === file.name)
    );
  
    if (newFiles.length === 0) {
      this.props.error('Duplicate files are not allowed');
      return;
    }
    else{
      this.props.error('');
    }
  
    const newDocuments = newFiles.map(file => ({
      FileId,
      VideoId,
      FileName: file.name,
      Title: file.name,
      CreatedDate: file.lastModifiedDate,
      Extension: file.name.split('.').pop(),
      File: file
    }));
  
    const updatedDocuments = [...this.state.documents, ...newDocuments];
  
    this.setState({ documents: updatedDocuments }, async () => {
      try {
        const documentArray = newFiles.map(file => ({ FileName: file.name }));
        const res = await API.postTutorialDocument({
          VideoId: VideoId,
          Documents: documentArray
        });
  
        if (res.status === 200) {
          const newUpdatedData = res.data.map((response, index) => {
            const doc = newFiles[index];
            return {
              VideoId,
              FileId: response.FileId,
              DocumentGeneratedFileName: response.FileId,
              FileName: doc.name,
              Id: response.DocumentId,
              Title: doc.name,
              Extension: doc.name?.split('.').pop()
            };
          });
  
          const documentsWithIds = updatedDocuments.map(doc => {
            const updatedDoc = newUpdatedData.find(data => data.FileName === doc.FileName);
            return updatedDoc ? { ...doc, DocumentId: updatedDoc.Id } : doc;
          });
  
          this.setState(prevState => ({
            documents: documentsWithIds,
            updatedDocumentData: [...prevState.updatedDocumentData, ...newUpdatedData],
            documentResponce: [...prevState.documentResponce, ...res.data]
          }), () => {
            this.props.document(this.state);
          });
        }
      } catch (error) {
        console.error('Error posting documents:', error);
        this.props.error('An error occurred while uploading the document');
      }
    });
  };

  componentDidMount(){
    this.props.document(this.state)

    const { documents,documentMaxLengthError} = this.state

    if(Object.keys(documents).length < 5){
      this.setState({
        documentMaxLengthError:false
      })
    }
    else{
      this.setState({
        documentMaxLengthError:true
      })
    }
    this.props.setDocumentMaxLengthError(documentMaxLengthError)
  }
  


  handleDocumentRemove = (id) => {
    const updatedDocuments = this.state.updatedDocumentData.filter(doc => doc.Id !== id);
    const updatedDocs = this.state.documents.filter(doc => doc.DocumentId !== id);
    const documentMaxLengthError = updatedDocs.length >= 5;
  
    this.setState({
      updatedDocumentData: updatedDocuments,
      documents: updatedDocs,
      documentMaxLengthError
    }, () => {
      this.props.document(this.state.documents);
      this.props.setDocumentMaxLengthError(documentMaxLengthError);
    });
  
    API.deleteDocument(id)
      .then((res) => {
        if (res.status === 200) {
          this.props.document(this.state.documents);
        }
      })
      .catch((error) => {
        console.error('Error deleting document:', error);
      });
  }
  

  render() {
    return (
      <div className="video-upload-docs">
        {
          this.state.documents.length > 0 &&
          this.state.documents.map((document, index) => (
            <DocumentCheck
              key={index}
              handleDocumentRemove={() => this.handleDocumentRemove(document.DocumentId)}
              Extension={document.FileName.split('.').pop()}
              Filename={document.FileName.split('.')}
              CreatedDate={document.File.lastModifiedDate}
            />
          ))
        }

        <Dropzone onDrop={this.onDrop}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps({ className: "dropfile" })}>
              <input {...getInputProps()} />
              <svg width="31" height="31" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fillRule="evenodd">
                  <rect className="svg-for-menu" width="31" height="31" rx="8.448" />
                  <path fill="#FFF" d="M8.912 7.363h12.4v16.275h-12.4z" />
                  <path
                    d="M7.825 6.2h15.35c.894 0 1.625.73 1.625 1.625v15.35a1.63 1.63 0 0 1-1.625 1.625H7.825A1.63 1.63 0 0 1 6.2 23.175V7.825c0-.894.73-1.625 1.625-1.625zm12.07 6.713-1.348 1.334-2.093-2.068v10.254h-1.908V12.178l-2.093 2.069-1.35-1.334L15.5 8.567l4.396 4.346z"
                    className="svg-for-menu"
                  />
                </g>
              </svg>
              <p className="drop-text">Drag and drop files</p>
              <p> or</p>
              <label for="drag-file" className="pri-button">Choose a file</label>
            </div>
          )}
        </Dropzone>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    VideoData: state.tutorials.VideoData,
  };
};

const mapDispatchToProps = {
  postTutorialDocumentAction: postTutorialDocument,
};

export default connect(mapStateToProps, mapDispatchToProps)(Draganddrop);
