import { saveAs } from "file-saver";
import React from "react";
import TranscriptLabel from "../TranslatedComponents/TranscriptLabel"
import DocumentsLabel from "../TranslatedComponents/DocumentsLabel"
import DescriptionLabel from "../TranslatedComponents/DescriptionLabel"
import NotesLabel from "../TranslatedComponents/NotesLabel"
import { connect, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import DocumentCheck from "../../modules/Admin/Common/DocumentCheck";
import { fetchTutorialsDetail } from "../../store/actions/tutorials";
import store from "../../store/configureStore";
import API from "../../utils/API";
import RichTextEditorNotes from "../Common/RichTextEditorNotes"
import download from "../Common/DownloadFile";
import { height } from "@mui/system";
import { useTranslation } from "react-i18next";
import { CourseType, extension } from "../../modules/Admin/Common/Constants";
import { toTime } from "../Common/Constants";


function Detailtabs(props) {
  const [documents, setDocument] = React.useState([]);
  const [notes, setNotes] = React.useState({});
  const { t } = useTranslation()

  const No_Data_available = t("No_Data_available")
  const Playlist = t("Playlist")
  const Notes_Saved_Success = t("Notes_Saved_Success")
  const Save = t("Save")




  const downloadImage = (url, filename) => {
    saveAs(url, `${filename}.${url.split('.').pop()}`) // Put your image url here.
  }
  const [content, setContent] = React.useState('');
  const tabsMargin = `tabsStyling ${document.body.dir === "ltr" ? "tabs-r-margin" : "tabs-l-margin"}`

  // const styles = {
  //   mainContainer: {
  //     display: "flex",
  //     flexDirection: "column",
  //   },
  //   container: {
  //     display: "flex",

  //     marginBottom: "20px",
  //   },

  //   img: {
  //     height: "126px",
  //     width: "150px",
  //   },
  //   TitleDesc: {
  //     marginLeft: "20px",
  //   },
  //   h3: {
  //     fontSize: "18px",
  //     fontWeight: "600",
  //     height: "50px",
  //     width: "230px",
  //   },
  //   p: {
  //     fontSize: "10px",
  //   },
  // };

  const { videoId } = props;
  // const { Transcript, Description } = props.props.Contents.Videos[videoId];
  // const Documents = props.props.Contents.Videos[videoId].Documents;
  const userId = useSelector(() => store.getState().profileData.id);
  const params = useParams()

  const handleContent = (data) => {
    setContent(data.trim())
  }

  React.useEffect(() => {
    if(props.props.Course.CourseType === CourseType.Offline)
    {
      API.getDocument({ userId: userId, videoId: props.props.Contents[videoId].Id }).then(res =>res.status === 200 &&setDocument(res.data))
      API.getNotes({ userId: userId, videoId: props.props.Contents[videoId].Id }).then(res => setNotes(res.data))
    }
    else{
      API.getWebinarDocument(props.props.Contents[videoId].Id).then(res => console.log(res))
    }
  }, [])

  React.useEffect(() => {
    console.log(props.props.Course.CourseType === CourseType.Offline)
    if(props.props.Course.CourseType === CourseType.Online)
      {
      API.getWebinarDocument(props.props.Contents[videoId].Id).then(res =>setDocument(res.data))
      }
    else{
    }
  }, [videoId])

  const handleSaveNotes = () => {
    API.saveNotes(userId, props.props.Contents[videoId].Id, content).then(res => res.status === 201 && props.success({
      body: Notes_Saved_Success,
      isTrue: true
    })).finally(() => {
      props.fetchTutorialsDetail(props.props.Contents[videoId].Id)
      API.getDocument({ userId: userId, videoId: props.props.Contents[videoId].Id }).then(res => setDocument(res.data))
      API.getNotes({ userId: userId, videoId: props.props.Contents[videoId].Id }).then(res => setNotes(res.data))
    })
  }

  console.log(documents)


  return (
    <div>
      <Tabs>
        <TabList>
          <Tab className={tabsMargin}><DescriptionLabel /></Tab>
          <Tab className={tabsMargin}><DocumentsLabel /></Tab>
          <Tab className={tabsMargin}><TranscriptLabel /></Tab>
          {props.props.CourseType === CourseType.Offline && <Tab className={tabsMargin}><NotesLabel /></Tab>}
        </TabList>

        <TabPanel>
          <div id="lms-tab-1" className="lms-tabcontent current">
            <p>{props.props.Contents[videoId].Description}</p>
          </div>
        </TabPanel>

        <TabPanel>
          {documents.length > 0 ? (
            <div id="lms-tab-2" className="lms-tabcontent document-close-icon">
              <ul className="video-de-docs course-docs-tab"
              >
                {documents?.map((document, idx) => {
                  return (
                    extension.filter(a => a.exstension.includes(document?.Extension?.trim())).length !== 0 &&
                    <li
                      key={idx}>
                      <div onClick={() => { download(document.CdnUrl, document.Title + '.' + document.CdnUrl.split('.').pop()) }}>
                        <DocumentCheck Extension={document.Extension} Filename={document.Title ? document.Title : ""} CreatedDate={document.CreatedDate} />
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : (
            <div>{No_Data_available}</div>
          )}
        </TabPanel>

        <TabPanel>
          <div id="lms-tab-3" className="lms-tabcontent">
            <p>{props.props.Contents[videoId].Transcript ? props.props.Contents[videoId].Transcript : <div>{No_Data_available}</div>}</p>
          </div>
        </TabPanel>

        {props.props.CourseType === CourseType.Offline &&
          <TabPanel>
            <div id="lms-tab-4" className="lms-tabcontent current">
              <RichTextEditorNotes value={notes.Notes} content={handleContent} />
              <button className="notes-tab-btn sec-btn savebtn" onClick={handleSaveNotes}>{Save}</button>
            </div>
          </TabPanel>
        }
      </Tabs>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {

  return {
    tutorials: state.tutorials.tutorialsDetail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTutorialsDetail: (id) => dispatch(fetchTutorialsDetail(id)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Detailtabs);

