import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { getAllUsers } from '../../store/actions/AllUsersAction';
import { fetchAllGroups, getAllRoles } from './store/actions/admin';
import UserSearchFilter from "./UserData/SearchFilter";
import UserDatagriddata from "./UserData/Datagriddata";

function UserManagement(props) {
  const [filterUser, setFilterUser] = React.useState({
    Count: 0,
    Data: [],
  });
  const [userSelection, setUserSelection] = React.useState(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState('');

  const handleUserSelection = (event) => {
    setUserSelection(event);
  };

  const handleFilterUser = (event) => {
    console.log('sort', event);
    setFilterUser({
      Count: event.Count,
      Data: event.Data,
    });
  };

  const handleModalAction = (event) => {
    setIsOpen(event);
  };

  const hanldeSearchValue = (event) => {
    setSearchValue(event);
  };

  const refreshUserList = async () => {
    await props.fetchAllUserAction({
      PageNumber: 1,
      PageSize: 20,
    });
    setUserSelection(null);
  };

  useEffect(() => {
    const { fetchAllUserAction, fetchRolesAction, groupPageNumber, fetchAllGroups } = props;
    
    fetchAllUserAction({
      PageNumber: 1,
      PageSize: 20,
    });
    
    fetchRolesAction({
      PageNumber: 1,
      PageSize: 0,
    });
    
    groupPageNumber === 2 &&
      fetchAllGroups({
        PageNumber: 1,
        PageSize: 20,
      });
  }, []);

  return (
    <section id="colle-grid" className='analytics-admin-space'>
      <UserSearchFilter 
        refreshUserList={refreshUserList}
        selection={userSelection}
        filterData={handleFilterUser}
        modal={handleModalAction}
        searchValue={hanldeSearchValue}
      />
      
      <UserDatagriddata 
        refreshUserList={refreshUserList}
        selection={handleUserSelection}
        filterData={filterUser}
        modal={isOpen}
        searchValue={searchValue}
      />
    </section>
  );
}

const mapStateToProps = (state) => ({
  role: state.profileData.Role,
  videoCollectionPageNumber: state.tutorials.collectionPageNumber,
  courseCollectionPageNumber: state.courses.collectionPageNumber,
  groupPageNumber: state.groups.groupPageNumber,
  quizPageNumber: state.quiz.QuizPagenumber,
  questionPageNumber: state.questions.QuestionPageNumber,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    fetchAllUserAction: getAllUsers,
    fetchRolesAction: getAllRoles,
    fetchAllGroups,
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
