import React, { useEffect, useState, useRef } from "react";
import BreadcrumbsNav from "../Common/BreadcrumbsNav";
import HorizontalStepper from "../../../components/Common/HorizontalStepper";
import UploadedVideosEdit from "../Common/UploadedVideosEdit";
import UploadCourse from "./UploadCourse";
import { Backdrop, Alert } from '@mui/material';
import LoadingScreen from "../../../components/Common/LoadingScreen";
import AttachQuiz from "../Common/AttachQuiz";
import CoursePreviews from "../Common/CoursePreviews";
import CourseCreation from "../Common/CourseCreation";
import {documentPath, CourseType, tempVideoPath, thumbnailPath, uploadedVideoStateUploadedToTemp } from "../Common/Constants";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { postTutorialDocument, updateDocumentVideo, updateTutorialVideo } from "../store/actions/admin";
import API from "../utils/API";
import { default as UserAPI } from "../../../utils/API";
import { useNavigate } from "react-router-dom";
import Uploadwithsas from "../Common/Uploadwithsas";
import Spinner from "../../../components/Common/Spinner";

const CreateOfflineCourse = (props) => {

    const [activeStep, setActiveStep] = useState(0);
    const [selectedVideo, setSelectedVideo] = useState({});
    const [modalLoader, setModalLoader] = useState(false);
    const [successResposnse, setSuccessResposnse] = useState('');
    const [courseId, setCourseId] = React.useState(null);
    const [alert, setAlert] = useState({
        active: false,
        severity: '',
        message: '',
        loading: false
    })
    const [getFiles, setGetFiles] = useState([]);
    const [editedVideoData, setEditedData] = useState([])
    const [getQuizCourseList, setQuizCourseList] = useState([]);
    const [getThumbnail, setGetThumbnail] = React.useState('')
    const [mockFiles, setMockfiles] = useState([])
    const [thumbnailPreview, setPrivewThumbnail] = useState('');
    const [selectedQuiz, setSelectedQuiz] = useState([]);
    const [videolength, setVideoLength] = React.useState(0);
    const [videoPoints, setVideoPoints] = useState(null);
    const [selectedCategoryId, setSelectedCategoryId] = useState(null)
    const [isFormFilled, setFormFilled] = useState(false);
    const [isVideoUploaded,setVideoUploaded] = useState(false)
    const childRef = useRef();
    const navigate = useNavigate();
    const [EditedTitle, setEditedVideoTitle] = useState('')
    const [EditedDescription, setEditedDescription] = useState('')
    const [EditedTranscript, setEditedTranscript] = useState('')
    const [EditedVideoPoints, setEditedVideoPoints] = useState('')
    const [document, setDocument] = useState({})
    const [documentFile, setDocumentFile] = useState({})
    const [documentResponce, setDocumentResponce] = useState({})
    const [videoThumbnail, setVideoThumbnail]= useState(null)
    const[videoThumPreview, setVideoThumPreview]= useState('')
    const handleSuceessResponse = (e) => {
        setSuccessResposnse(e)
    }

    const triggerChildCreateCourse = () => {
        if (childRef.current) {
            childRef.current.triggerCreateLiveCourse();
        }
    };

    const handleBack = () => {
        setActiveStep((prevStep) => {
            if (prevStep === 1) {
                setFormFilled(false);
            }
            return prevStep - 1;
        });
    }


    useEffect(() => {
        if (activeStep === 1 && mockFiles.length > 0) {
            setVideoUploaded(true);
        } else {
            setVideoUploaded(false);
        }
    }, [activeStep, mockFiles]);
    

    const handleNext = () => {
        if (activeStep === 0) {
            triggerChildCreateCourse();
        }
        else if (activeStep === 3) {
            handleAddVideoToPlaylist();
        }
        if (activeStep > 0 && activeStep < 3) {
            setActiveStep(prevStep => prevStep + 1);
        }
    }

    const handleThumbnailPreview = (setPreview) => {
        setPrivewThumbnail(setPreview)
    }

    const handleFormFilled = (data) => {
        setFormFilled(data)
    }

    const handleBackDropClose = () => {
        setAlert({
            active: false,
            severity: '',
            message: '',
            loading: false
        })
    };

    const handleCourseId = (id) => {
        setCourseId(id);
    };

    useEffect(() => {
        setTimeout(() => {
            handleBackDropClose();
        }, 4000);
    }, [alert.active]);

    const addFileToMockFiles = (newFile) => {
        setMockfiles((prevFiles) => [...prevFiles, newFile]);
    };

    const handleCreateDocument = () => {
        if (document && Object.keys(document).length > 0){
            props.postTutorialDocumentAction({
                FileName: document.name,
                VideoId :mockFiles[0].VideoId,
                FileId:mockFiles[0].FileId,
                File: document,
            })
        }
      };

    const handleAddVideoToPlaylist = (e) => {
        setModalLoader(true);

        const quizIds = getQuizCourseList
            .filter(item => item.Type === 0)
            .map(quiz => quiz.Id);
    
        const videoIds = getQuizCourseList
            .filter(item => item.Type === "video")
            .map(video => video.Id);
    
    
        const VideoapiCalls = videoIds.map(VideoId => 
            API.addVideoToPlaylist({ PlaylistId: courseId, VideoId })
        );
    
        const QuizApiCalls = quizIds.map((QuizId, index) => 
            API.assignQuiztoPlaylist({
                quizId: QuizId,
                playlistId: courseId,
                indexSequence: index + 1
            })
        );
    
        const apiCalls = [...VideoapiCalls, ...QuizApiCalls];
    
        Promise.all(
            mockFiles.map(video => {
                const videoData = mockFiles.find(a => a.FileId === video.FileId);
    
                return API.updateTutorial({
                    categoryId: parseInt(selectedCategoryId.Id),
                    description: editedVideoData[0].videoDescription.trim(),
                    VideoExtension: videoData.VideoExtension,
                    VideoName: editedVideoData[0].videoTitle.trim(),
                    title: editedVideoData[0].videoTitle.trim(),
                    transcript: editedVideoData[0].videoTranscript.trim(),
                    VideoData: { VideoId: videoData.VideoId, FileId: videoData.FileId },
                    videolength: videolength,
                    Points: editedVideoData[0].videoPoints
                })
                .then(() => {
                    return Uploadwithsas(getFiles, videoData.FileId, videoData.SasToken, tempVideoPath);
                })
                .then(() => {
                    return Uploadwithsas(videoThumbnail[0], videoData.FileId, videoData.ThumbNail, thumbnailPath);
                })
                .then(res => {
                    if (res?.status === 201) {
                        return API.updateVideoData({
                            Id: video.VideoId,
                            State: uploadedVideoStateUploadedToTemp
                        });
                    } else {
                        throw new Error('Video upload failed');
                    }
                })
                .then(() => {
                    if (documentFile && documentFile.length > 0) {
                        Promise.all(documentResponce.map((doc, index) => 
                            Uploadwithsas(documentFile[index], doc.FileId, doc.DocumentSasToken, documentPath)
                        ));
                        API.updateDocument(document)
                    }
                })
                .then(() => {
                    return Promise.all(apiCalls);
                });
            })
        )
        .then(responses => {
            const flattenedResponses = responses.flat();
            const allSuccess = flattenedResponses.every(res => res.status === 200);
            if (allSuccess) {
                return UserAPI.fetchCoursesDetail(courseId)
                    .then(res => {
                        if (res.status === 200) {
                            setAlert({
                                active: true,
                                severity: 'success',
                                message: 'Offline course created successfully',
                            });
                        }
                    });
            } else {
                throw new Error('Some API calls failed');
            }
        })
        .catch(err => {
            console.error("Error during process:", err);
            setAlert({
                active: true,
                severity: 'error',
                message: 'Failed to add videos or quizzes to playlist',
            });
        })
        .finally(() => {
            setModalLoader(false);
            setTimeout(() => {
                navigate('/collection/coursecollection');
            }, 4000);
        });
    };
    
    

    const handleRemoveVideo = (id) =>{
        API.deleteVideo(id)
        setMockfiles(mockFiles.filter((video) => (video.VideoId !== id)))
    }


    const renderStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <div className="p-5 mt-5 bg-white">
                        <CourseCreation
                            ref={childRef}
                            setAlert={setAlert}
                            activeStep={activeStep}
                            onCourseIdReceived={handleCourseId}
                            setCourseType={CourseType.Offline}
                            setActiveStep={setActiveStep}
                            formFilled={handleFormFilled}
                            setFormFilled={setFormFilled} 
                            setSelectedCategoryId = {setSelectedCategoryId} />
                    </div>
                );
            case 1:
                return (
                    <div>
                        {mockFiles.length === 0 ? (
                            <>
                                <UploadCourse
                                    setPreviewThumb={handleThumbnailPreview}
                                    updateMockFiles={addFileToMockFiles}
                                    selectVideo={(e) => setSelectedVideo(e)}
                                    getThumbnail={(e) => { setGetThumbnail(e) }}
                                    successResponse={successResposnse}
                                    getFiles={(e) => setGetFiles(e)}
                                    setAlert={setAlert}
                                    videolength={(e) => { setVideoLength(e) }}
                                />
                                {alert.loading && <LoadingScreen />}
                            </>
                        ) : (
                            <UploadedVideosEdit
                                setDocument = {setDocument}
                                setDocumentFile = {setDocumentFile}
                                setDocumentResponce = {setDocumentResponce}
                                mockFiles={mockFiles}
                                selectedVideo={selectedVideo}
                                editedData={setEditedData}
                                editedVideoData= {editedVideoData}
                                selectedPlaylist={getQuizCourseList}
                                thumbnailPreview={thumbnailPreview}
                                uploadedCourseId={courseId}
                                removeVideo = {handleRemoveVideo}
                                selectedCategoryId = {selectedCategoryId}
                                setEditedVideoTitle = {setEditedVideoTitle}
                                EditedTitle = {EditedTitle}
                                setEditedDescription = {setEditedDescription}
                                EditedDescription = {EditedDescription}
                                setEditedTranscript = {setEditedTranscript}
                                EditedTranscript = {EditedTranscript}
                                setEditedVideoPoints = {setEditedVideoPoints}
                                EditedVideoPoints = {EditedVideoPoints}
                                setVideoThumbnail={setVideoThumbnail}
                                videoThumbnail={videoThumbnail}
                                setVideoThumPreview={setVideoThumPreview}
                                videoThumPreview = {videoThumPreview}
                                formFilled={handleFormFilled}
                                setFormFilled={setFormFilled} 
                            />
                        )}
                    </div>

                );
            case 2:
                return (
                    <div>
                        <AttachQuiz
                            setQuizCourseList={setQuizCourseList}
                            editedData = {editedVideoData}
                            selectedQuiz = {getQuizCourseList}
                            setSelectedQuiz={setSelectedQuiz}
                            thumbnailPreview={videoThumPreview} />
                    </div>
                );
            case 3:
                return (
                    <div className="mt-3">
                        <CoursePreviews
                            thumbnailPreview={thumbnailPreview}
                            getQuizCourseList={getQuizCourseList}
                            mockFiles={getQuizCourseList} 
                            editedVideoData={editedVideoData} />
                    </div>
                );
            default:
                return (
                    <div>
                        <h1>Unknown Step</h1>
                    </div>
                );
        }
    };
    return (
        <>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={alert.active}
                onClick={handleBackDropClose}
            >
                <Alert severity={alert.severity} style={{ position: "absolute", top: 20 }}>
                    {alert.message}
                </Alert>
            </Backdrop>
            <div className="video-tab-con">
                <BreadcrumbsNav
                    activeTtile="Create Offline Course"
                    setStudioLandingPage={props.setStudioLandingPage}
                />
                <HorizontalStepper className="mt-3" steps={['Course Details', 'Upload Video', 'Quiz', 'Preview']} activeStep={activeStep} />
                <div className='d-flex justify-content-center studio-course-creation'>
                    <div className='upload-con-width'>
                        {!modalLoader ? renderStepContent(activeStep) : 
                        <div className="spinner-wrap">
                        <Spinner animation="border" variant="dark" />
                      </div>}
                        <div className="fixed-btns d-flex justify-content-between">
                            <button onClick={handleBack} disabled={activeStep === 0} className="filter-clear-all m-0">Back</button>
                            <button onClick={handleNext} disabled={activeStep === 0 && !isFormFilled || activeStep === 1 && !isVideoUploaded || activeStep === 1 && !isFormFilled} className="filter-clear-search">Next</button>
                        </div>
                    </div>
                </div>
            </div></>
    )
}

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(
        {
            updateTutorialVideoAction: updateTutorialVideo,
            updateDocumentVideoAction: updateDocumentVideo,
            postTutorialDocumentAction: postTutorialDocument,
        },
        dispatch
    );
};

const mapStateToProps = (state) => ({
    VideoData: state.tutorials.VideoData,
    DocumentData: state.tutorials.DocumentData,
    VideoName: state.tutorials.VideoName,
    VideoExtension: state.tutorials.VideoExtension,
    DocumentExtension: state.tutorials.DocumentExtension,
    DocumentName: state.tutorials.DocumentName,
    isVideoUploaded: state.tutorials.isVideoUploaded,
    courses: state.courses.courses.Playlist,
    playlistCategories: state.category.playlistCategories,
});


export default connect(mapStateToProps, mapDispatchToProps)(CreateOfflineCourse);