import * as React from "react";
import { connect } from "react-redux";
import { FormHelperText } from '@mui/material';
import Selectcategory from "./Selectcategory";
import Draganddrop from "./Draganddrop";
import Uploadwithsas from "../Common/Uploadwithsas";
import API from "../utils/API";
import LoadingScreen from "../../../components/Common/LoadingScreen";
import { updateDocumentVideo, updateTutorialVideo } from "../store/actions/admin";
import { documentPath, tempVideoPath, thumbnailPath, uploadedVideoStateUploadedToTemp, validationLimits, errorTitles } from "../Common/Constants";

class VerticalTimeline extends React.Component {
  constructor(props) {
    super(props);
    console.log(props)
    this.state = {
      document: {},
      title: "",
      description: "",
      transcript: "",
      titleError: "",
      descriptionError: "",
      documentError: "",
      transcriptError: "",
      categoryId: null,
      categoryIdError:"",
      videoUploadResponse: {
        status: false,
        message: '',
        alertType: ''
      },
      thumbnailUploadResponse: {
        status: false,
        message: '',
        alertType: ''
      },
      documentUploadResponse: {
        status: false,
        message: '',
        alertType: ''
      },
      isInputFocus: false,
      isLoadingScreenActive: false,
      thumbnailPreview: '',
      thumbnailError: false,
      thumbnail: null,
    };
    this.thumbnailFile = React.createRef();
  }

  validate = () => {
    let titleError = "";
    let descriptionError = "";
    let categoryIdError = "";
    let thumbnailError = "";

    if (this.state.title.length === 0) {
      titleError = errorTitles.titleEmptyError;
    } else if (this.state.title.length > validationLimits.videoTitleMax) {
      titleError = errorTitles.titleExceedError;
    }

    if (this.state.description.length === 0) {
      descriptionError = errorTitles.descriptionError;
    } else if (this.state.description.length > validationLimits.videoDescriptionMax) {
      descriptionError = errorTitles.descriptionExceedError;
    }

    if (!this.state.categoryId || this.state.categoryId === 'null') {
      categoryIdError = "Select any category";
    }

    if (!this.state.thumbnail || this.state.thumbnail.length === 0) {
      thumbnailError = "Thumbnail is required";
    }

    if (titleError || descriptionError || categoryIdError || thumbnailError) {
      this.setState({ titleError, descriptionError, categoryIdError, thumbnailError });
      return false;
    }

    return true;
};

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleInpActive = () => {
    this.setState({ isInputFocus: true });
  }

  handleCategoryId = (e) => {
    this.setState({
      categoryId: e,
    });
  };

  document = (e) => {
    this.setState({
      document: e
    })
  }

  handleDocumentError = (e) => {
    this.setState({
      documentError: e
    })
  }

  handleThumbnail = (event) => {
    const file = event.target.files[0];
    const fileType = file && file.type;
    const validTypes = ['image/png', 'image/jpeg', 'image/jpg'];

    if (validTypes.includes(fileType)) {
      const reader = new FileReader();
      reader.onloadend = () => {
        this.setState({
          thumbnailPreview: reader.result,
          thumbnailError: false,
          thumbnail: file,
        });
      };
      reader.readAsDataURL(file);
    } else {
      this.setState({
        thumbnailError: true,
        thumbnailPreview: '',
        thumbnail: null,
      });
    }
  };
  

  handleThumbnailClick = () => {
    this.thumbnailFile.current.click();
  };

  handleUploadClick = async () => {
    const {
      updateTutorialVideoAction,
      updateDocumentVideoAction,
      VideoData,
      DocumentData,
      DocumentName,
      DocumentExtension,
      VideoName,
      VideoExtension,
      files,
      videolength
    } = this.props;

    const { documentResponce, updatedDocumentData, uploadedFiles } = this.state.document;

    const isValid = this.validate();
    if (isValid) {
      this.setState({ isLoadingScreenActive: true });

      updateTutorialVideoAction({
        ...this.state,
        VideoData,
        VideoName,
        VideoExtension,
        videolength
      });

      try {
        const uploadPromises = [];

        const videoUpload = Uploadwithsas(
          files,
          VideoData.FileId,
          VideoData.VideoSasToken,
          tempVideoPath
        ).then(res => {
          if (res.status === 201) {
            this.props.setAlert({
              active: true,
              severity: 'success',
              message: 'Video created successfully',
            });
            this.props.videoUploaded({
              status: true,
              alertType: 'success',
              FileId: VideoData.FileId,
            });
            return API.updateVideoData({
              Id: VideoData.VideoId,
              State: uploadedVideoStateUploadedToTemp,
            });
          } else {
            this.props.videoUploaded({
              status: false,
              alertType: 'error',
              FileId: VideoData.FileId,
            });
          }
        });
        uploadPromises.push(videoUpload);

        if (this.state.thumbnail && this.state.thumbnail instanceof File) {
          const thumbnailUpload = Uploadwithsas(
            this.state.thumbnail,
            VideoData.FileId,
            VideoData.ThumbnailSasToken,
            thumbnailPath
          ).then(res => {
            if (res.status !== 201) {
              this.props.videoUploaded({
                status: false,
                alertType: 'error',
                FileId: VideoData.FileId,
              });
            }
          });
          uploadPromises.push(thumbnailUpload);
        }

        if (uploadedFiles && uploadedFiles.length > 0) {
          const documentUploads = documentResponce.map((doc, index) =>
            Uploadwithsas(
              uploadedFiles[index],
              doc.FileId,
              doc.DocumentSasToken,
              documentPath
            )
          );
          uploadPromises.push(...documentUploads);
          uploadPromises.push(API.updateDocument(updatedDocumentData));
        }

        await Promise.all(uploadPromises);

        this.props.setAlert({
          active: true,
          severity: 'success',
          message: 'All uploads completed successfully',
        });

        this.props.videoUploaded({
          status: true,
          alertType: 'success',
          FileId: VideoData.FileId,
        });

        this.props.navigate('/collection/videocollection');

      } catch (error) {
        this.props.setAlert({
          active: true,
          severity: 'error',
          message: 'Failed to create videos',
        });
        console.error('Upload failed', error);
      } finally {
        this.setState({ isLoadingScreenActive: false });
      }
    } else {
      this.validate();
    }
  };


  render() {
    const { thumbnailPreview, thumbnailError } = this.state;
    const { isVideoUploaded, selectedFile } = this.props;

    return isVideoUploaded ? (
      <div className="m-5">
        {this.state.isLoadingScreenActive && <LoadingScreen />}
        <div className="video-desc  lms-flex-between">
          <h4>Video Title</h4>
        </div>
        <div className={`video-input ${this.state.isInputFocus ? "is-inp-focus" : "is-inp-not-focus"}`}>
          <input
            type="text"
            name="title"
            value={this.state.title}
            onChange={this.handleInputChange}
            onFocus={this.handleInpActive}
          />
        </div>
        <h6 style={{ color: "red" }}>{this.state.titleError}</h6>
        <div className="thumbnail-upload">
          <input
            type="file"
            style={{ display: 'none' }}
            accept="image/png, image/jpeg, image/jpg"
            ref={this.thumbnailFile}
            onChange={this.handleThumbnail}
          />

          {thumbnailPreview.length === 0 ? (
            <div className="webinar-upload-part-parent">
              <div
                className="video-upload thumb-upload webinar-upload-part"
                onClick={this.handleThumbnailClick}
              >
                <svg
                  width="36"
                  height="36"
                  viewBox="0 0 84 84"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="64" height="64" rx="17.6" className="svg-for-menu" />
                  <rect x="18.3994" y="15.2001" width="25.6" height="33.6" fill="white" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.1544 12.8C26.7177 12.8 37.2819 12.8 47.8453 12.8C49.6908 12.8 51.1998 14.3091 51.1998 16.1546C51.1998 26.718 51.1998 37.2822 51.1998 47.8455C51.1998 49.691 49.6908 51.2001 47.8453 51.2001C37.2819 51.2001 26.7177 51.2001 16.1544 51.2001C14.3089 51.2001 12.7998 49.691 12.7998 47.8455C12.7998 37.2821 12.7998 26.7179 12.7998 16.1546C12.7998 14.3091 14.3089 12.8 16.1544 12.8ZM41.0751 26.6586C40.1466 27.5764 39.2181 28.495 38.2896 29.4128C36.8498 27.9896 35.4092 26.5663 33.9694 25.143C33.9694 32.1992 33.9694 39.2563 33.9694 46.3126C32.6558 46.3126 31.343 46.3126 30.0294 46.3126C30.0294 39.2555 30.0294 32.1992 30.0294 25.1421C28.5896 26.5654 27.149 27.9896 25.7092 29.4128C24.7807 28.495 23.8521 27.5764 22.9236 26.6586C25.9491 23.6677 28.9738 20.6777 31.9993 17.6868C35.0249 20.6777 38.0496 23.6678 41.0751 26.6586H41.0751Z"
                    className="svg-for-menu"
                  />
                </svg>
                <h4>Upload thumbnail image (Only PNG/JPEG)</h4>
              </div>
              {thumbnailError && (
                <FormHelperText sx={{ fontSize: 12, color: 'red' }}>
                  Upload only PNG/JPEG format
                </FormHelperText>
              )}
            </div>
          ) : (
            <div className="replace-image-con">
              <img
                src={thumbnailPreview}
                className="thumbnail"
                alt="Thumbnail preview"
              />
              <button onClick={this.handleThumbnailClick}>Replace image</button>
              {thumbnailError && (
                <FormHelperText sx={{ fontSize: 10, color: 'red', position: 'absolute', top: '107px' }}>
                  Upload only PNG/JPEG format
                </FormHelperText>
              )}
            </div>
          )}
        </div>
        <Selectcategory onSeletecCategoryId={this.handleCategoryId} />
        {this.state.categoryId === null && <h6 style={{ color: "red" }}>{this.state.categoryIdError}</h6>}
        <h4>Description</h4>
        <textarea
          id="video-descripation"
          rows="12"
          cols="50"
          form="usrform"
          placeholder="Add Description"
          name="description"
          value={this.state.description}
          onChange={this.handleInputChange}
        ></textarea>
        <h6 style={{ color: "red" }}>{this.state.descriptionError}</h6>
        <h4 className="m-30">Documents</h4>
        <Draganddrop document={this.document} error={this.handleDocumentError} />
        {this.state.documentError.length > 0 && <h6 style={{ color: "red",marginTop:'1vw' }}>{this.state.documentError}</h6>}
        <h4 className="mt-3">Transcript</h4>
        <textarea
          id="video-descripation"
          rows="12"
          cols="50"
          form="usrform"
          placeholder="Add Transcript"
          name="transcript"
          value={this.state.transcript}
          onChange={this.handleInputChange}
        ></textarea>

        <div className="fixed-video-btns">
          <button className="back-btn">Back</button>
          <button onClick={this.handleUploadClick}>Upload</button>
        </div>
      </div>
    ) : (
      <div></div>
    );
  }
}

const mapStateToProps = (state) => {
  const { VideoData, VideoName, DocumentData, VideoExtension, DocumentExtension, DocumentName, isVideoUploaded } =
    state.tutorials;

  return {
    VideoData: VideoData,
    DocumentData: DocumentData,
    VideoName: VideoName,
    VideoExtension: VideoExtension,
    isVideoUploaded: isVideoUploaded,
    DocumentExtension: DocumentExtension,
    DocumentName: DocumentName,
    tenant: state.tenant.tenant
  };
};

const mapDispatchToProps = {
  updateTutorialVideoAction: updateTutorialVideo,
  updateDocumentVideoAction: updateDocumentVideo,
};

export default connect(mapStateToProps, mapDispatchToProps)(VerticalTimeline);
