import React, { Component, useEffect, useState } from "react";
import { MsalContext } from "@azure/msal-react";
import { msalConfig, loginRequest } from "../../utils/authConfig";
import { getUserDetails } from "../../store/actions/userActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { fetchOrganisationDetailAction } from "../../store/actions/OrganistaionAction";
import {
  FusionAuthLoginButton,
  FusionAuthLogoutButton,
  RequireAuth,
  useFusionAuth
} from '@fusionauth/react-sdk';
import API from "../../modules/Admin/utils/API";
import { fetchTenantDetailsAction } from "../../modules/Admin/store/actions/admin";

function LoginFuc(props) {
  const [authenticated, setAuthenticated] = useState(false)
  let searchParams = new URLSearchParams(window.location.search);
  const uri = searchParams.get("redirectUri");
  const { isAuthenticated, user, login } = useFusionAuth();

  if (authenticated && user) {
    props.getUserDetails(user)
    props.fetchTenant(user.tid)
    return <Navigate to={`/`} />;
  }
  else {
    return login()
  }
}


const mapStateToProps = (state) => ({
  profile: state.user,
  tenant: state.tenant
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getUserDetails: getUserDetails,
      fetchOrganisation: fetchOrganisationDetailAction,
      fetchTenant: fetchTenantDetailsAction,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginFuc);
