import moment from 'moment'
import React from 'react'
import { extension } from './Constants'
import CloseIcon from '@mui/icons-material/Close';



export default function DocumentCheck(props) {
    return (
        extension.filter(a => a.exstension.includes(props.Extension.trim())).length !== 0 &&
        <li className='dropfile'>
            <div className='d-flex align-items-center justify-content-end' onClick={props.handleDocumentRemove}><CloseIcon/></div>
            <div className='documents'>
                <div className="lms-tabcardspace">
                    <img
                        style={{ width: '30%' }}
                        src={extension.find(a => a.exstension.includes(props.Extension.trim())).src}
                        alt=""
                    ></img>
                </div>
                <div className="card-heading card-heading-m">
                    {props.Filename}
                </div>
                <div className="lms-flex-between lms-datespace">
                    <div className="card-date lms-carddate">
                        {moment(props.CreatedDate).format("DD/MM/YYYY")}
                    </div>
                    <button className="doc-button">{extension.find(a => a.exstension.includes(props.Extension.trim())).exstension}</button>
                </div>
            </div>
        </li>

    )
}