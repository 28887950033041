import React from 'react';
import Moment from 'moment';

const VideoUploadedDate = ({ createdDate }) => {
  const timeAgo = Moment(Moment.utc(createdDate).local().format()).fromNow();

  return <p>{timeAgo}</p>;
};

export default VideoUploadedDate;
